
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import '~@angular/material/theming';
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();
$carbon-primary:(
  50 : #edf9f3,
  100 : #d3efe0,
  200 : #b6e5cc,
  300 : #99dbb8,
  400 : #83d3a8,
  500 : #6dcb99,
  600 : #65c691,
  700 : #5abe86,
  800 : #50b87c,
  900 : #3eac6b,
  A100 : #fcfffd,
  A200 : #c9ffde,
  A400 : #96ffbf,
  A700 : #7dffb0,
  contrast: (
      50 : #ffffff,
      100 : #ffffff,
      200 : #ffffff,
      300 : #ffffff,
      400 : #ffffff,
      500 : #ffffff,
      600 : #ffffff,
      700 : #ffffff,
      800 : #ffffff,
      900 : #ffffff,
      A100 : #ffffff,
      A200 : #ffffff,
      A400 : #ffffff,
      A700 : #ffffff,
  )
);
$carbon-secondary: (
    50 : #eaebeb,
    100 : #cccdcd,
    200 : #aaacac,
    300 : #878a8b,
    400 : #6e7172,
    500 : #545859,
    600 : #4d5051,
    700 : #434748,
    800 : #3a3d3e,
    900 : #292d2e,
    A100 : #86daf7,
    A200 : #57ccf3,
    A400 : #18c5ff,
    A700 : #00befd,
    contrast: (
        50 : #ffffff,
        100 : #ffffff,
        200 : #ffffff,
        300 : #ffffff,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);
$carbon-warn: (
  50 : #fef6e5,
  100 : #fce8bd,
  200 : #fad992,
  300 : #f8ca66,
  400 : #f7be45,
  500 : #f5b324,
  600 : #f4ac20,
  700 : #f2a31b,
  800 : #f09a16,
  900 : #ee8b0d,
  A100 : #ffffff,
  A200 : #fff3e5,
  A400 : #ffdab3,
  A700 : #ffce99,
  contrast: (
      50 : #000000,
      100 : #000000,
      200 : #000000,
      300 : #000000,
      400 : #000000,
      500 : #000000,
      600 : #000000,
      700 : #000000,
      800 : #000000,
      900 : #000000,
      A100 : #000000,
      A200 : #000000,
      A400 : #000000,
      A700 : #000000,
  )
);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$carbon-calculator-primary: mat-palette($carbon-primary);
$carbon-calculator-accent: mat-palette($carbon-primary);

// The warn palette is optional (defaults to red).
$carbon-calculator-warn: mat-palette($carbon-warn);


// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$carbon-calculator-theme: mat-light-theme((
  color: (
    primary: $carbon-calculator-primary,
    accent: $carbon-calculator-accent,
    warn: $carbon-calculator-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($carbon-calculator-theme);

/* You can add global styles to this file, and also import other style files */


// @import 'bootstrap/dist/css/bootstrap.min.css';
$primary: #6dcb99;
$secondary:#545859;

$bg: #c9e0da;

$danger: #ff595a;
$success: #6dcb99;
$warning: #f5b324;
$info: #88aae3;

$body-color: #545859;

// $body-bg: #f3f3f0;

// $theme-colors: (
//   "primary": $primary,
//   "danger": $danger
// );

// @import 'bootstrap/scss/bootstrap';
/* Importing Bootstrap SCSS file. */
@import "~bootstrap/scss/bootstrap";


html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

@font-face {
  font-family: "fieldwork-demibold";
  src: local("fieldwork-demibold"), url(./assets/fonts/Fieldwork-Geo-Demibold.otf) format("opentype");
}

@font-face {
  font-family: "fieldwork-bold";
  src: local("fieldwork-bold"), url(./assets/fonts/Fieldwork-Geo-Bold.otf) format("opentype");
}

@font-face {
  font-family: "fieldwork-light";
  src: local("fieldwork-light"), url(./assets/fonts/Fieldwork-Geo-Light.otf) format("opentype");
}

.app-heading-1{
  font-size: 64px;
  font-weight: 700;
  line-height: 1.06;
  letter-spacing: 1px;
  color: $secondary;
}
.app-heading-2{
   font-family: "fieldwork-demibold";
  font-size: 32px;
  font-weight: 600;
  line-height: 1.13;
  letter-spacing: .3px;
  color: $secondary;
}
.app-heading-3{
  font-family: "fieldwork-demibold";
  font-size: 20px;
  font-weight: 700;
  line-height: 1.2;
  letter-spacing: .5px;
  color: $secondary;
}

.app-button-text{
  font-family: "fieldwork-light";
}

.app-text{
  font-family: "fieldwork-light, sans-serif";
  // font-family: sans-serif;
  font-weight: 300;
  font-style: normal;
  color: $secondary;
  line-height: 1.3;
  margin: 0;
  font-size: 1.125rem;
}
.app-text-bold{
  font-family: "fieldwork-bold, sans-serif";
  // font-family: sans-serif;
  font-weight: 700;
  font-style: normal;
  color: $secondary;
  line-height: 1.3;
  margin: 0;
  font-size: 1.125rem;
}
.app-disclaimer{
  font-family: "fieldwork-light, sans-serif";
  font-weight: 300;
  color: $secondary;
  line-height: 1.3;
  margin: 0;
}
.app-disclaimer-strong{
  font-family: "fieldwork-light, sans-serif";
  color: $secondary;
  line-height: 1.3;
  margin: 0;
}
.bg-colour{
  background-color: $bg;
}

.green-snackbar {
  background: $success;
  color: white;
}
.green-snackbar button {
  background-color: $success;
  color: white !important;
  border: none;
}

.red-snackbar {
  background: $danger;
  color: white;
}
.red-snackbar button {
  background-color: $danger;
  color: white !important;
  border: none;
}


.btn-circle {
  width: 23px;
  height: 23px;
  padding: 1px 1px;
  border-radius: 13px;
  font-size: 10px;
  text-align: center;
}